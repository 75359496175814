// Router.tsx
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import Error from './components/layouts/Error/Error';

import Download from './pages/Download/Download';
import PaymentStatusPage from './pages/BuyChallenge/PaymentStatusPage';
import BuyChallenge from './pages/BuyChallenge/BuyChallenge';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';

import Users from './pages/Admin/Users/Users';
import CashManagement from './pages/Admin/CashManagement/CashManagement';
import Reports from './pages/Admin/Reports/Reports';
import Promotion from './pages/Admin/Promotion/Promotion';
import Kyc from './pages/Admin/Kyc/Kyc';
import Challenges from './pages/Admin/Challenges/Challenges';
// import Dashboard from './pages/Admin/Dashboard/Dashboard';
import Funded from './pages/Admin/Funded/Funded';
import { Partner } from './features/partner/partner';
import Discord from './pages/user/discord';
import Certificate from './pages/user/certificate';
import Calendar from './pages/user/calendar';
import Profile from './pages/user/profil';
import Login from './pages/user/login';
import ClientArea from './features/area/pages/ClientArea/ClientArea';
import FundedArea from './features/area/pages/FundedArea/FundedArea';
import LightDashboard from './features/admin/pages/LightDashboard/LightDashboard';
import { useAuth } from './context/AuthContext/AuthContext';
import Loader from './components/Loader/Loader';
import Dashboard from './features/admin/pages/Dashboard/Dashboard';
import TimeTracker from './pages/Admin/TimeTracker';
import FreeTrial from './pages/user/freeTrial/FreeTrial';
// import TimeTracker from './features/admin/pages/TimeTracker/TimeTracker';

export default function Router() {
  const navigate = useNavigate();
  const { permissions, isPermissionsLoading } = useAuth();
  const { t } = useTranslation(['unauthorized', 'comingSoon', 'notFound']);

  if (isPermissionsLoading) {
    return <Loader />;
  }

  const hasDashboardAccess =
    Array.isArray(permissions) && permissions.includes('ACCESS_DASHBOARD');

  const hasTimeTrackerAccess =
    Array.isArray(permissions) && permissions.includes('ACCESS_TIME_TRACKER');

  return (
    <Routes>
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/login" element={<Login />} />
      <Route path="/buy-challenge" element={<BuyChallenge />} />
      <Route path="/free-trial" element={<FreeTrial />} />
      <Route
        path="/buy-challenge/payment-status/:paymentId"
        element={<PaymentStatusPage />}
      />
      {/* Route fallback pour gérer les URLs avec query params */}
      <Route
        path="/buy-challenge/payment-status"
        element={<PaymentStatusPage />}
      />

      <Route path="/" element={<PrivateRoute />}>
        <Route index element={<ClientArea />} />
        <Route path="/trader-area" element={<FundedArea />} />
        <Route path="/partner-area" element={<Partner />} />
        <Route path="/download" element={<Download />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/kyc" element={<Profile />} />
        <Route path="/economic-calendar" element={<Calendar />} />
        <Route path="/certificate" element={<Certificate />} />
        <Route
          path="/calculator"
          element={
            <Error
              documentTitle="Coming Soon"
              title={t('title', { ns: 'comingSoon' })}
              subtitle={t('text', { ns: 'comingSoon' })}
              button={t('button', { ns: 'comingSoon' })}
              onClick={() => navigate('/')}
            />
          }
        />
        <Route path="/discord" element={<Discord />} />
        <Route path="admin" element={<PrivateRoute roles={['ROLE_ADMIN']} />}>
          <Route
            path="/admin/dashboard"
            element={hasDashboardAccess ? <Dashboard /> : <LightDashboard />}
          />
          <Route
            path="/admin/time-tracker"
            element={
              hasDashboardAccess ? (
                <TimeTracker />
              ) : (
                <Navigate to={'/unauthorized'} />
              )
            }
          />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/cash-management" element={<CashManagement />} />
          <Route path="/admin/reports" element={<Reports />} />
          <Route path="/admin/promotion" element={<Promotion />} />
          <Route path="/admin/kyc" element={<Kyc />} />
          <Route path="/admin/challenges" element={<Challenges />} />
          <Route path="/admin/funded" element={<Funded />} />
        </Route>
      </Route>
      <Route
        path="/unauthorized"
        element={
          <Error
            documentTitle="Unauthorized"
            title={t('title')}
            subtitle={t('text')}
            button={t('button')}
            onClick={() => navigate('/')}
          />
        }
      />
      <Route
        path="*"
        element={
          <Error
            documentTitle="404 - Not Found"
            title={t('title', { ns: 'notFound' })}
            subtitle={t('text', { ns: 'notFound' })}
            button={t('button', { ns: 'notFound' })}
            onClick={() => navigate('/')}
          />
        }
      />
    </Routes>
  );
}
