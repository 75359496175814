import { useState } from 'react';

import { useThemeContext } from '../../context/ThemeContext/ThemeContext';

import classes from './_quantitySelector.module.scss';
import Icon from '../userInterface/Icon/Icon';

import { Button, IconButton } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

interface QuantitySelectorProps {
  initialQuantity: number;
  onQuantityChange: (newQuantity: number) => void;
}

const QuantitySelector: React.FC<QuantitySelectorProps> = ({
  initialQuantity,
  onQuantityChange,
}) => {
  const { isDarkMode } = useThemeContext();
  const { t } = useTranslation('buyChallenge');

  const [quantity, setQuantity] = useState(initialQuantity);

  const handleIncrement = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    onQuantityChange(newQuantity);
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      onQuantityChange(newQuantity);
    }
  };

  return quantity === 0 ? (
    <Button
      variant="contained"
      size="small"
      endIcon={<AddIcon />}
      onClick={handleIncrement}
      sx={{
        backgroundColor: isDarkMode ? 'white' : '#333333',
        color: isDarkMode ? 'black' : 'white',
        '&:hover': {
          backgroundColor: isDarkMode ? '#F5F5F5' : '#4D4D4D',
        },
      }}
    >
      {t('challengesCards.ADD_BUTTON')}
    </Button>
  ) : (
    <div className={classes.container}>
      {quantity === 1 ? (
        <IconButton onClick={handleDecrement}>
          <DeleteIcon sx={{ fill: isDarkMode ? 'white' : '#333333' }} />
        </IconButton>
      ) : (
        <button onClick={handleDecrement} className={classes.button}>
          <Icon
            color="white"
            size={28}
            path="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM7 11V13H17V11H7Z"
          />
        </button>
      )}
      <span>{quantity}</span>
      <button onClick={handleIncrement} className={classes.button}>
        <Icon
          color="white"
          size={28}
          path="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 11H7V13H11V17H13V13H17V11H13V7H11V11Z"
        />
      </button>
    </div>
  );
};

export default QuantitySelector;
