import { useEffect, useState } from 'react';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import getFundedData from '../../services/get/pieChart/funded/getFunded';
import { FundedOverviewDTO } from '../../services/get/pieChart/funded/models';

export const useFundedData = (isActive: boolean) => {
  const { tokens } = useAuth();
  const [data, setData] = useState<FundedOverviewDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (isActive) {
      setLoading(true);

      const fetchFundedData = async () => {
        if (tokens) {
          try {
            const response = await getFundedData(tokens.accessToken);
            setData(response);
          } catch (err) {
            setError(err as Error);
          } finally {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      };

      const intervalId = setInterval(() => {
        fetchFundedData();
      }, 30000);

      fetchFundedData();

      return () => clearInterval(intervalId);
    }
  }, [tokens, isActive]);

  return { data, loading, error };
};
