import React, { useState, useEffect } from 'react';
import {
  Button,
  Stack,
  Snackbar,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadCard from '../FileUploadCard/FileUploadCard';
import { useKYCUpload } from '../../hooks/useKYCUpload';
import { useTranslation } from 'react-i18next';
import { fetchKYC } from '../../services/get/kyc/getKYC'; // Assurez-vous que le chemin est correct
import { useAuth } from '../../../../../context/AuthContext/AuthContext'; // Assurez-vous que le chemin est correct
import { KYCResponse } from '../../services/get/kyc/interfaces';

const KYCContent: React.FC = () => {
  const { t } = useTranslation('profile');
  const {
    handleFileUpload,
    handleSendAllDocuments,
    snackbarOpen,
    snackbarMessage,
    handleCloseSnackbar,
  } = useKYCUpload();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { tokens } = useAuth();

  const [kycResponse, setKycResponse] = useState<KYCResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!tokens?.accessToken) {
          throw new Error('Access token is not available');
        }
        const data = await fetchKYC(tokens?.accessToken);
        setKycResponse(data);
      } catch (err: any) {
        setError(err.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [tokens?.accessToken]);

  // Mapper les statuts avec leurs types de document
  const kycStatuses =
    kycResponse?.kycFileList.reduce(
      (acc, file) => {
        acc[file.documentType] = file.documentStatus;
        return acc;
      },
      {} as Record<string, string>,
    ) || {};

  // État pour suivre les fichiers à envoyer
  const [filesToSend, setFilesToSend] = useState<Record<string, File | null>>({
    ADDRESS_PROOF: null,
    IDENTITY_PROOF_RECTO: null,
    IDENTITY_PROOF_VERSO: null,
  });

  const handleSendDocuments = () => {
    // Filtrer les documents qui ne sont pas validés
    const pendingFiles = Object.entries(filesToSend).filter(
      ([documentType]) => kycStatuses[documentType] !== 'APPROVED',
    );

    if (pendingFiles.length === 0) {
      alert(t('KYC.NO_PENDING_DOCUMENTS_TO_SEND'));
      return;
    }

    // Préparer les fichiers à envoyer
    const formData = new FormData();
    pendingFiles.forEach(([documentType, file]) => {
      if (file) {
        formData.append(documentType, file);
      }
    });

    // Envoyer les documents réels
    handleSendAllDocuments();
  };

  const handleFileUploadWrapper = (file: File, documentType: string) => {
    setFilesToSend((prev) => ({
      ...prev,
      [documentType]: file,
    }));
    handleFileUpload(file, documentType);
  };

  if (loading) {
    return <div>{t('KYC.LOADING')}</div>;
  }

  if (error) {
    return <div>{t('KYC.ERROR_LOADING_DATA', { error })}</div>;
  }

  return (
    <Stack
      spacing={3}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        spacing={3}
        direction={isMobile ? 'column' : 'row'}
        justifyContent="center"
        alignItems="center"
      >
        <FileUploadCard
          label={t('KYC.TEXT_NATIONAL_ID_CARD_FRONT')}
          documentType="IDENTITY_PROOF_RECTO"
          onFileUpload={handleFileUploadWrapper}
          status={kycStatuses['IDENTITY_PROOF_RECTO']}
        />
        <FileUploadCard
          label={t('KYC.TEXT_NATIONAL_ID_CARD_BACK')}
          documentType="IDENTITY_PROOF_VERSO"
          onFileUpload={handleFileUploadWrapper}
          status={kycStatuses['IDENTITY_PROOF_VERSO']}
        />
        <FileUploadCard
          label={t('KYC.TEXT_PROOF_OF_ADDRESS')}
          documentType="ADDRESS_PROOF"
          onFileUpload={handleFileUploadWrapper}
          status={kycStatuses['ADDRESS_PROOF']}
        />
      </Stack>

      <Button
        variant="contained"
        onClick={handleSendDocuments}
        disabled={Object.values(filesToSend).every((file) => !file)}
      >
        {t('KYC.BUTTON_SEND_ALL_DOCUMENTS')}
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Stack>
  );
};

export default KYCContent;