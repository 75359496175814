import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import useDocumentTitle from '../../hooks/useDocumentTitle';

import Button from '../../components/userInterface/Button/Button';
import Input from '../../components/userInterface/Input/Input';
import ErrorMessage from '../../components/Error/Error';

import Error from '../../components/layouts/Error/Error';

import classes from './_forgotPassword.module.scss';

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [emailSend, setemailSend] = useState<boolean>(false);

  useDocumentTitle('Forgot password');
  const { t } = useTranslation(['forgotPassword', 'common']);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    axios
      .post(
        `https://${process.env.REACT_APP_API_URL}/api/password-reset/initiate?email=${email}`,
      )
      .then((response) => {
        setError('');
        setemailSend(true);
      })
      .catch((error) => {
        setError(t('errorOccuredTryAgain'));
      });
  };

  const handleBack = () => {
    window.history.back();
  };

  return emailSend ? (
    <Error
      documentTitle="Reset password"
      title={t('checkYourEmail')}
      subtitle={t('checkEmailText')}
      button={t('sendMailButton')}
      onClick={() => setemailSend(false)}
    />
  ) : (
    <div className={classes.container}>
      <img
        className={classes.logo}
        src={require('../../assets/images/fullLogo.png')}
        alt=""
      />
      <div className={classes.titles}>
        <h1> {t('forgotYourPassword')} </h1>
        <h2>{t('pleaseEnterYourEmail')}</h2>
      </div>
      <div className={classes.formContainer}>
        {error && <ErrorMessage error={error} />}

        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.formGroup}>
            <label htmlFor="password"> {t('email')} </label>
            <Input
              id="email"
              type="email"
              placeholder={t('emailPlaceholder')}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={classes.buttons}>
            <Button variant="outline" onClick={handleBack}>
              {t('back', { ns: 'common' })}
            </Button>
            <Button type="submit" variant="primary">
              {t('continue')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ForgotPassword;
