import { useEffect, useState } from 'react';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import getOverviewData from '../../services/get/pieChart/overview/getOverview';
import { AccountOverviewDTO } from '../../services/get/pieChart/overview/models';

export const useOverviewData = () => {
  const { tokens } = useAuth();
  const [data, setData] = useState<AccountOverviewDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);

    const fetchOverviewData = async () => {
      if (tokens) {
        try {
          const response = await getOverviewData(tokens.accessToken);
          setData(response);
        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    const intervalId = setInterval(() => {
      fetchOverviewData();
    }, 30000);

    fetchOverviewData();

    return () => clearInterval(intervalId);
  }, [tokens]);

  return { data, loading, error };
};
