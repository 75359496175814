import { useState, useEffect } from 'react';
import getCashEntry from '../../services/get/chart/cashEntry/getCashEntry';
import { DateAmountDTO } from '../../services/get/chart/cashEntry/models';
import { useAuth } from '../../../../context/AuthContext/AuthContext';

export const useCashEntry = (
  timeframe: string = 'TODAY',
  shouldFetch: boolean,
) => {
  const { tokens } = useAuth();
  const [data, setData] = useState<DateAmountDTO[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!shouldFetch || !tokens) return;

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getCashEntry(tokens.accessToken, timeframe);
        setData(response);
        setError(null);
      } catch (err: any) {
        setError(err.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    const intervalId = setInterval(() => {
      fetchData();
    }, 30000);

    fetchData();

    return () => clearInterval(intervalId);
  }, [tokens, timeframe, shouldFetch]);

  return { data, loading, error };
};
