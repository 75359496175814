import axiosInstance from '../../utils/axios';
import { JWTPayload, ResponseShop, ResponseShopConnected } from './interface';

export function decodeJWT(token: string): JWTPayload | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const decodedToken = JSON.parse(atob(base64));
    return decodedToken as JWTPayload;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
}

export const fetchShop = async (): Promise<ResponseShop> => {
  try {
    const response = await axiosInstance.get('/shop');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchShopConnected = async (
  accessToken: string,
): Promise<ResponseShopConnected> => {
  const decoded = decodeJWT(accessToken);

  try {
    const response = await axiosInstance.get<ResponseShopConnected>(
      `/shop/connected?userId=${decoded?.userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const retryPayment = async (paymentRef: string) => {
  try {
    const response = await axiosInstance.post(
      `/payments/vasu/retry/${paymentRef}`,
    );
    return response.data.authzUrl;
  } catch (error) {
    throw error;
  }
};

export const retryPaymentAPS = async (paymentRef: string) => {
  try {
    const response = await axiosInstance.post(`/shop/retry/${paymentRef}`);
    return response.data.url;
  } catch (error) {
    throw error;
  }
};

export const retryPaymentVasu = async (paymentRef: string, birthDate: any) => {
  try {
    const response = await axiosInstance.post(
      `/payments/leikur/retry/${paymentRef}?birthDate=${birthDate}`,
    );
    return response.data.url;
  } catch (error) {
    throw error;
  }
};

export const applyPromoCode = async (
  accessToken: string | null,
  code: string,
  price: number,
  affiliationLink: string | null,
  useOffer: boolean, // Ajout du paramètre useOffer
) => {
  let userUuid = null;
  if (accessToken) {
    const decoded = decodeJWT(accessToken);
    userUuid = decoded?.userId;
  }

  try {
    const headers = accessToken
      ? { Authorization: `Bearer ${accessToken}` }
      : {};

    const response = await axiosInstance.get(`/promocodes/code`, {
      headers,
      params: {
        code,
        price,
        userUuid,
        affiliationLink,
        useOffer, // On passe useOffer en paramètre
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
