import axiosInstance from '../../../../../../utils/axios';
import { toast } from 'react-toastify';

import { IFreeTrialService } from './interfaces';
import { FreeTrialRequest } from './models';
import { handleError } from './errors';

class FreeTrialService implements IFreeTrialService {
  async startFreeTrial(data: FreeTrialRequest): Promise<string> {
    try {
      const response = await axiosInstance.post<string>(
        `/challenges/free-trial/start`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data; // Ex: "Free trial started successfully."
    } catch (error: any) {
      if (error.response?.status === 500) {
        toast.error('You already have an active or recent free trial', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        handleError(error); // Gère d'autres types d'erreurs.
      }
      throw error; // Rethrow pour un éventuel traitement en amont.
    }
  }
}

export default new FreeTrialService();
