import React, { useEffect, useState } from 'react';
import { useThemeContext } from '../../../context/ThemeContext/ThemeContext';
import {
  Box,
  Button,
  Divider,
  Step,
  StepLabel,
  Stepper,
  Typography,
  TextField,
  Grid,
  Card,
  CardContent,
  Stack,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';

import useFreeTrial from './hooks/useFreeTrial';
import ECN_DARK_BUTTON from '../../../assets/images/paymentStatus/ECN_Dark.png';
import STP_DARK_BUTTON from '../../../assets/images/paymentStatus/STP_Dark.png';
import ECN_LIGHT_BUTTON from '../../../assets/images/paymentStatus/ECN_Light.png';
import STP_LIGHT_BUTTON from '../../../assets/images/paymentStatus/STP_Light.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FreeTrial = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useThemeContext();
  const { t } = useTranslation('freeTrial');

  const {
    activeStep,
    selectedCard,
    setSelectedCard,
    selectedAccountType,
    setSelectedAccountType,
    formValues,
    handleFormChange,
    formErrors,
    stepError,
    handleNext,
    handleBack,
    startFreeTrial,
    isSubmitting,
    hasToken,
  } = useFreeTrial();

  // État pour stocker les options de pays
  const [countryOptions, setCountryOptions] = useState([]);

  // Récupération des pays autorisés depuis l'API
  useEffect(() => {
    fetch(`https://${process.env.REACT_APP_API_URL}/api/localisation/allowed-countries`)
      .then((response) => response.json())
      .then((data) => {
        setCountryOptions(data);
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des pays:', error);
      });
  }, []);

  const steps = hasToken
    ? [
        t('STEPPER_TITLES.CHOOSE_ACCOUNT'),
        t('STEPPER_TITLES.CHOOSE_TYPE'),
        t('STEPPER_TITLES.ACCOUNT_CREATED'),
      ]
    : [
        t('STEPPER_TITLES.PREPARE_ACCOUNT'),
        t('STEPPER_TITLES.CHOOSE_ACCOUNT'),
        t('STEPPER_TITLES.CHOOSE_TYPE'),
        t('STEPPER_TITLES.ACCOUNT_CREATED'),
      ];

  const accountOptions = [
    '10 000 USD',
    '25 000 USD',
    '50 000 USD',
    '100 000 USD',
    '200 000 USD',
    '300 000 USD',
  ]; // Liste des options de compte

  // État pour le numéro de téléphone
  const [phoneValue, setPhoneValue] = useState('');

  // État pour l'erreur du type de compte
  const [accountTypeError, setAccountTypeError] = useState(false);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 600,
        mx: 'auto',
        mt: 4,
        textAlign: 'center',
      }}
    >
      {/* Titre */}
      <Typography variant="h4" gutterBottom>
        {t('TITLE')}
      </Typography>

      {/* Séparateur */}
      <Divider sx={{ my: 2 }} />

      {/* Stepper */}
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel
              error={
                (!!stepError && activeStep === index) ||
                (index === (hasToken ? 1 : 2) && accountTypeError)
              }
            >
              {index === (hasToken ? 1 : 2) && accountTypeError
                ? 'Please select an account type.'
                : label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Contenu de l'étape */}
      {!hasToken && activeStep === 0 && (
        <Box component="form" sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            {[
              {
                label: t('STEPPER_CONTENT.STEP_ZERO.LABELS.FIRST_NAME'),
                field: 'firstName',
              },
              {
                label: t('STEPPER_CONTENT.STEP_ZERO.LABELS.LAST_NAME'),
                field: 'lastName',
              },
              {
                label: t('STEPPER_CONTENT.STEP_ZERO.LABELS.COUNTRY'),
                field: 'country',
              },
              {
                label: t('STEPPER_CONTENT.STEP_ZERO.LABELS.CITY'),
                field: 'city',
              },
              {
                label: t('STEPPER_CONTENT.STEP_ZERO.LABELS.STREET'),
                field: 'street',
              },
              {
                label: t('STEPPER_CONTENT.STEP_ZERO.LABELS.ZIP_CODE'),
                field: 'postalCode',
              },
              {
                label: t('STEPPER_CONTENT.STEP_ZERO.LABELS.EMAIL_ADDRESS'),
                field: 'email',
              },
            ].map(({ label, field }, index) => (
              <Grid item xs={12} sm={index < 2 ? 6 : 12} key={field}>
                {field === 'country' ? (
                  <TextField
                    label={label}
                    variant="outlined"
                    fullWidth
                    required
                    select
                    value={formValues[field] || ''}
                    onChange={(e) => handleFormChange(field, e.target.value)}
                    error={!!formErrors[field]}
                    helperText={formErrors[field]}
                  >
                    {countryOptions.map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <TextField
                    label={label}
                    variant="outlined"
                    fullWidth
                    required
                    value={formValues[field]}
                    onChange={(e) => handleFormChange(field, e.target.value)}
                    error={!!formErrors[field]}
                    helperText={formErrors[field]}
                  />
                )}
              </Grid>
            ))}

            {/* Champ pour le numéro de téléphone */}
            <Grid item xs={12}>
              <MuiTelInput
                label={t('STEPPER_CONTENT.STEP_ZERO.LABELS.PHONE_NUMBER')}
                value={phoneValue}
                onChange={(newValue) => {
                  setPhoneValue(newValue);
                  handleFormChange('phoneNumber', newValue);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Sélection du compte */}
      {activeStep === (hasToken ? 0 : 1) && (
        <Grid container spacing={2}>
          {accountOptions.map((amount, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card
                variant="outlined"
                onClick={() => setSelectedCard(index)}
                sx={{
                  textAlign: 'center',
                  padding: 2,
                  cursor: 'pointer',
                  borderWidth: 2,
                  borderColor:
                    selectedCard === index ? 'primary.main' : 'transparent',
                  '&:hover': { borderColor: 'primary.main' },
                }}
              >
                <CardContent>
                  <Typography variant="h6">{amount}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Sélection du type de compte */}
      {activeStep === (hasToken ? 1 : 2) && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            {t('STEPPER_CONTENT.STEP_TWO.TITLE')}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t('STEPPER_CONTENT.STEP_TWO.SUBTITLE')}
          </Typography>
          <Stack mt={4} mb={4} gap={2}>
            <Typography variant="body1" textAlign={'left'} gutterBottom>
              <Typography
                variant="body1"
                component={'span'}
                fontWeight={'bold'}
              >
                {t('STEPPER_CONTENT.STEP_TWO.STP')}
              </Typography>{' '}
              {t('STEPPER_CONTENT.STEP_TWO.STP_EXPLANATION')}
            </Typography>
            <Typography variant="body1" textAlign={'left'} gutterBottom>
              <Typography
                variant="body1"
                fontWeight={'bold'}
                component={'span'}
              >
                {t('STEPPER_CONTENT.STEP_TWO.ECN')}
              </Typography>{' '}
              {t('STEPPER_CONTENT.STEP_TWO.ECN_EXPLANATION')}
            </Typography>
          </Stack>

          <Stack gap={2} justifyContent="center">
            <Button
              variant="outlined"
              size="large"
              sx={{
                padding: '16px 8px',
                borderColor: selectedAccountType === 'STP' ? 'red' : '',
                backgroundColor:
                  selectedAccountType === 'STP' ? '#e74750' : 'transparent',
                '&:hover': {
                  backgroundColor:
                    selectedAccountType === 'STP' ? '#d7393f' : undefined,
                },
              }}
              onClick={() => {
                setSelectedAccountType('STP');
                setAccountTypeError(false); // Réinitialise l'erreur
              }}
            >
              <img
                src={
                  selectedAccountType === 'STP'
                    ? STP_LIGHT_BUTTON
                    : isDarkMode
                    ? STP_LIGHT_BUTTON
                    : STP_DARK_BUTTON
                }
                width="100"
                alt="STP Account"
              />
            </Button>
            <Button
              variant="outlined"
              size="large"
              sx={{
                padding: '16px 8px',
                borderColor: selectedAccountType === 'ECN' ? 'red' : '',
                backgroundColor:
                  selectedAccountType === 'ECN' ? '#e74750' : 'transparent',
                '&:hover': {
                  backgroundColor:
                    selectedAccountType === 'ECN' ? '#d7393f' : undefined,
                },
              }}
              onClick={() => {
                setSelectedAccountType('ECN');
                setAccountTypeError(false); // Réinitialise l'erreur
              }}
            >
              <img
                src={
                  selectedAccountType === 'ECN'
                    ? ECN_LIGHT_BUTTON
                    : isDarkMode
                    ? ECN_LIGHT_BUTTON
                    : ECN_DARK_BUTTON
                }
                width="100"
                alt="ECN Account"
              />
            </Button>
          </Stack>
        </Box>
      )}

      {/* Compte créé */}
      {activeStep === (hasToken ? 2 : 3) && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            {t('STEPPER_CONTENT.STEP_THREE.TITLE')}
          </Typography>
          <Stack mt={4}>
            <Button variant="contained" onClick={() => navigate('/')}>
              {t('STEPPER_CONTENT.STEP_THREE.GO_TO_CHALLENGES_BUTTON')}
            </Button>
          </Stack>
        </Box>
      )}

      {/* Boutons de navigation */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button
          variant="outlined"
          onClick={handleBack}
          disabled={activeStep === 0 || activeStep === steps.length - 1} // Désactive le bouton sur la dernière étape
        >
          {t('STEPPER_BUTTONS.PREVIOUS')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            const lastStepIndex = steps.length - 2; // Avant-dernière étape

            // Vérification si le type de compte est sélectionné
            if (activeStep === (hasToken ? 1 : 2) && !selectedAccountType) {
              setAccountTypeError(true);
              return; // Empêche de passer à l'étape suivante
            }

            if (activeStep === lastStepIndex) {
              // Si on est sur l'avant-dernière étape, on envoie la requête
              startFreeTrial();
            } else {
              handleNext();
            }
          }}
          disabled={isSubmitting || activeStep === steps.length - 1}
        >
          {isSubmitting ? (
            <CircularProgress />
          ) : activeStep === steps.length - 1 ? (
            t('STEPPER_BUTTONS.FINISH')
          ) : (
            t('STEPPER_BUTTONS.NEXT')
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default FreeTrial;
