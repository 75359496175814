import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import useDocumentTitle from '../../hooks/useDocumentTitle';

import Input from '../../components/userInterface/Input/Input';
import Button from '../../components/userInterface/Button/Button';
import ErrorMessage from '../../components/Error/Error';

import Error from '../../components/layouts/Error/Error';

import classes from './_resetPassword.module.scss';

const ResetPassword = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const { t } = useTranslation('resetPassword');

  const navigate = useNavigate();

  const [tokenIsValid, setTokenIsValid] = useState<boolean>(true);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isReset, setIsReset] = useState<boolean>(false);

  useDocumentTitle('Reset password');

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    axios
      .get(`https://${process.env.REACT_APP_API_URL}/api/password-reset/verify?token=${token}`)
      .then((response) => {
        setTokenIsValid(true);
      })
      .catch((error) => {
        setTokenIsValid(false);
      });
  }, [token]);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError(t('passwordsDoNotMatch'));
      return;
    }
    setError('');

    axios
      .post(
        `https://${process.env.REACT_APP_API_URL}/api/password-reset/confirm?token=${token}`,
        { newPassword: password }
      )
      .then((response) => {
        setIsReset(true);
      });
  };

  if (isReset) {
    return (
      <Error
        documentTitle="Password successfully reset"
        title={t('passwordSuccessfullyReset')}
        subtitle={t('passwordResetYouCanLogin')}
        button={t('loginButton')}
        onClick={() => navigate('/login')}
      />
    );
  }

  return !tokenIsValid ? (
    <Error
      documentTitle="Reset password"
      title={t('linkExpired')}
      subtitle={t('linkExpiredText')}
      button={t('retryButton')}
      onClick={() => navigate('forgot-password')}
    />
  ) : (
    <div className={classes.container}>
      <img
        className={classes.logo}
        src={require('../../assets/images/fullLogo.png')}
        alt=""
      />
      <div className={classes.titles}>
        <h1> {t('resetPassword')} </h1>
        <h2>{t('pleaseCreatePassword')}</h2>
      </div>
      <div className={classes.formContainer}>
        <form className={classes.form} onSubmit={handleSubmit}>
          {error && <ErrorMessage error={error} />}
          <div className={classes.formGroup}>
            <label htmlFor="password"> {t('passwordLabel')} </label>
            <Input
              id="password"
              type="password"
              placeholder={t('passwordLabel')}
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className={classes.formGroup}>
            <label htmlFor="confirmation">{t('confirmPassword')}</label>
            <Input
              id="confirmation"
              type="password"
              placeholder={t('confirmPassword')}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </div>
          <Button type="submit" variant="primary">
            {t('resetPasswordButton')}
          </Button>
        </form>
      </div>
    </div>
  );
};
export default ResetPassword;
