import React, { useState } from 'react';
import { Button, Snackbar } from '@mui/material';
import { useUpgradeAccount } from '../ChallengeCard/hooks/useUpgradeAccount'; // Hook pour l'upgrade
import ContractDialog from '../ContractDialog/ContractDialog'; // Import ContractDialog
import { green } from '../../../../themes/colors/colors';
import { blue } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

interface UpgradeButtonProps {
  accountUUID: string;
  phase: string;
  isFreeTrial?: boolean;
}

const UpgradeButton: React.FC<UpgradeButtonProps> = ({
  accountUUID,
  phase,
  isFreeTrial,
}) => {
  const {
    handleUpgrade, // Renommez ici correctement depuis le hook
    snackbarOpen,
    snackbarMessage,
    snackbarType,
    handleSnackbarClose,
  } = useUpgradeAccount(); // Utilisation du hook pour l'upgrade
  const { t } = useTranslation('area');

  const [dialogOpen, setDialogOpen] = useState(false); // State pour le dialog

  const handleClick = () => {
    if (phase === 'PHASE_2' && !isFreeTrial) {
      setDialogOpen(true); // Ouvrir le dialogue pour "PHASE_2"
    } else {
      handleUpgrade(accountUUID); // Effectuer l'upgrade pour "PHASE_1"
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: phase === 'PHASE_1' ? green[300] : blue[500],
          '&:hover': {
            backgroundColor: phase === 'PHASE_1' ? green[400] : blue[800],
          },
        }}
        size="small"
        onClick={handleClick}
      >
        {phase === 'PHASE_1' 
          ? t('CHALLENGE_CARD.BUTTONS.START_STEP_2')
          : t('CHALLENGE_CARD.BUTTONS.BECOME_FUNDED')}
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        ContentProps={{
          style: {
            backgroundColor: snackbarType === 'success' ? 'green' : 'red',
          },
        }}
      />

      {/* Dialog de signature de contrat */}
      <ContractDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        selectedAccountLogin={accountUUID}
      />
    </>
  );
};

export default UpgradeButton;
