import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import SignatureCanvas from 'react-signature-canvas';
import fontkit from '@pdf-lib/fontkit';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../../context/AuthContext/AuthContext';

import {
  fetchContractInformations,
  submitContractInformations,
} from '../../../../services/client/client';
import { logout } from '../../../../services/auth/login';

import { Button, CircularProgress, TextField } from '@mui/material';

import contract from '../../../../assets/images/certificate/Independant_Contractor.pdf';
import HomemadeAppleFont from '../../../../assets/fonts/HomemadeApple.ttf';
import NotoSansFont from '../../../../assets/fonts/NotoSansRegular.ttf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { useNavigate } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PDFViewerProps {
  file: string | File;
  accountUUID: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ file, accountUUID }) => {
  const { tokens, isTokensLoading, user } = useAuth();
  const { t } = useTranslation(['pdfViewer']);

  const navigate = useNavigate();

  const sigCanvasRef = useRef<SignatureCanvas>(null);
  const [numPages, setNumPages] = useState<number>(0);
  const [signedPages, setSignedPages] = useState<boolean[]>([]);
  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [contractor, setContractor] = useState<string>('');
  const [isSending, setIsSending] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setSignedPages(new Array(numPages).fill(false));
  }

  useEffect(() => {
    setSignedPages(new Array(numPages).fill(false));
  }, [numPages]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          const target = mutation.target as HTMLElement;
          if (target.classList.contains('active')) {
            target.style.backgroundColor = 'transparent !important';
          }
        }
      });
    });

    const endOfContentElements = document.querySelectorAll('.endOfContent');

    endOfContentElements.forEach((element) => {
      observer.observe(element, { attributes: true });
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!file) return;

    let filePromise: Promise<string | ArrayBuffer>;

    if (file instanceof File) {
      filePromise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result instanceof ArrayBuffer) {
            resolve(reader.result);
          } else {
            reject(
              new Error("Le résultat de FileReader n'est pas un ArrayBuffer"),
            );
          }
        };
        reader.onerror = () => reject(reader.error);
        reader.readAsArrayBuffer(file);
      });
    } else if (typeof file === 'string') {
      filePromise = Promise.resolve(file);
    } else {
      filePromise = Promise.reject(new Error('Type de fichier non supporté'));
    }

    filePromise
      .then((fileData) => {
        const loadingTask = pdfjs.getDocument(fileData);
        loadingTask.promise.then(
          (pdf) => {
            setNumPages(pdf.numPages);
          },
          (reason) => {
            console.error(reason);
          },
        );
      })
      .catch((error) => {
        console.error('Erreur lors du chargement du fichier:', error);
      });
  }, [file]);

  useEffect(() => {
    const resizeCanvas = () => {
      if (sigCanvasRef.current) {
        const canvas = sigCanvasRef.current.getCanvas();
        const style = window.getComputedStyle(canvas);
        canvas.width = parseInt(style.width, 10);
        canvas.height = parseInt(style.height, 10);
      }
    };

    // Introduire un délai de 5 secondes avant de définir la taille
    const timer = setTimeout(() => {
      resizeCanvas();
    }, 3500); // 5000 millisecondes = 5 secondes

    // Réajuster la taille lors du redimensionnement de la fenêtre
    window.addEventListener('resize', resizeCanvas);

    return () => {
      clearTimeout(timer); // Nettoyer le timer quand le composant est démonté ou avant l'exécution d'un nouveau effet
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  const fetchData = useCallback(async () => {
    if (!isTokensLoading && tokens?.accessToken && user) {
      try {
        const profileData = await fetchContractInformations(tokens.accessToken);
        setName(profileData.fullName);
        setAddress(profileData.address);
        setContractor(profileData.fullName);
      } catch (error) {
        console.error('Error fetching data:', error);
        navigate('/profile');
      }
    } else if (!tokens) {
      logout();
    }
  }, [
    tokens,
    isTokensLoading,
    user,
    navigate,
    setName,
    setAddress,
    setContractor,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getInitiales = (name: string) => {
    return name
      .toLowerCase()
      .split(' ')
      .map((word: string) => word.charAt(0))
      .join('');
  };

  const initiales = getInitiales(contractor);

  const handleSign = (pageIndex: number) => {
    const newSignedPages = [...signedPages];
    newSignedPages[pageIndex] = true;
    setSignedPages(newSignedPages);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const handleContractorChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setContractor(event.target.value);
  };

  const uriToBuffer = (uri: any) => {
    const byteString = atob(uri.split(',')[1]);
    const bufferArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      bufferArray[i] = byteString.charCodeAt(i);
    }
    return bufferArray.buffer;
  };

  const fetchFont = async (fontUrl: any) => {
    const fontResponse = await fetch(fontUrl);
    const fontBuffer = await fontResponse.arrayBuffer();
    return fontBuffer;
  };

  const handleSend = async () => {
    if (isSending) return; // Empêche plusieurs clics pendant l'envoi

    setIsSending(true); // Désactive le bouton

    const isCanvasSigned = !sigCanvasRef.current?.isEmpty();

    if (!name || !address || !contractor) {
      toast.error(t('PLEASE_FILL_OUT_THE_CONTRACT'), {
        progressStyle: { backgroundColor: 'red' },
      });
      setIsSending(false); // Réactive le bouton
      return;
    }

    const allSigned = signedPages.slice(1, numPages - 1).every(Boolean);

    if (!allSigned) {
      toast.error(t('PLEASE_INITIAL_ALL_THE_PAGES'), {
        progressStyle: { backgroundColor: 'red' },
      });
      setIsSending(false); // Réactive le bouton
      return;
    }

    if (!isCanvasSigned) {
      toast.error(t('PLEASE_SIGN_THE_CONTRACT'), {
        progressStyle: { backgroundColor: 'red' },
      });
      setIsSending(false); // Réactive le bouton
      return;
    }

    try {
      const existingPdfBytes = await fetch(contract).then((res) =>
        res.arrayBuffer(),
      );
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      pdfDoc.registerFontkit(fontkit);

      const customFontBytes = await fetchFont(HomemadeAppleFont);
      const customFont = await pdfDoc.embedFont(customFontBytes);
      const notoSansFontBytes = await fetchFont(NotoSansFont);
      const notoSansFont = await pdfDoc.embedFont(notoSansFontBytes);

      const pages = pdfDoc.getPages();
      const firstPage = pages[1];
      const lastPage = pages[pages.length - 1];

      // Initiales sur toutes les pages signées
      pages.forEach((page, index) => {
        if (index !== 0 && index !== pages.length - 1) {
          page.drawText(initiales, {
            x: 550,
            y: 30,
            size: 14,
            font: customFont,
            color: rgb(0, 0, 0),
          });
        }
      });

      // Remplissage des informations sur la première et la dernière page
      firstPage.drawText(name, {
        x: 155,
        y: 505,
        size: 12,
        font: notoSansFont,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(address, {
        x: 165,
        y: 473,
        size: 12,
        font: notoSansFont,
        color: rgb(0, 0, 0),
      });
      lastPage.drawText(contractor, {
        x: 350,
        y: 300,
        size: 12,
        font: notoSansFont,
        color: rgb(0, 0, 0),
      });

      // Ajout de la signature sur la dernière page
      if (sigCanvasRef.current) {
        const canvas = sigCanvasRef.current.getCanvas();

        const signatureImageUri = canvas.toDataURL('image/png');
        const signatureImageBytes = uriToBuffer(signatureImageUri);
        const signatureImage = await pdfDoc.embedPng(signatureImageBytes);

        const signatureWidth = 193;
        const signatureHeight = 109;
        const xPosition = lastPage.getWidth() / 2; // Centré horizontalement
        const yPosition = 150;

        lastPage.drawImage(signatureImage, {
          x: xPosition,
          y: yPosition,
          width: signatureWidth,
          height: signatureHeight,
        });
      }

      // Conversion du PDF en binaire
      const pdfBytes = await pdfDoc.save();
      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
      const fileToSend = new File([pdfBlob], 'Signed_Contract.pdf', {
        type: 'application/pdf',
      });

      toast.info(t('CONTRACT_CURRENTLY_BEING_SENT'), {
        progressStyle: { backgroundColor: 'cornflowerblue' },
      });

      // Envoi du fichier via API
      if (tokens && tokens.accessToken) {
        await submitContractInformations(
          tokens.accessToken,
          accountUUID,
          fileToSend,
        );
        toast.success(t('CONTRACT_SENT_SUCCESSFULLY'), {
          progressStyle: { backgroundColor: 'green' },
        });
        window.location.reload(); // Redirection après succès
      } else {
        console.error('Access Token is missing');
        toast.error(t('AUTHENTICATION_ERROR'), {
          progressStyle: { backgroundColor: 'red' },
        });
      }
    } catch (error) {
      console.error(
        "Erreur lors de la génération ou de l'envoi du PDF:",
        error,
      );
      toast.error(t('CONTRACT_SEND_FAILED'), {
        progressStyle: { backgroundColor: 'red' },
      });
    } finally {
      setIsSending(false); // Réactive le bouton après l'opération
    }
  };

  const fontSizeStyles = {
    getFontSize() {
      const width = windowWidth;
      if (width < 320) {
        return '6px';
      } else if (width >= 320 && width <= 435) {
        return '10px';
      } else if (width >= 426 && width <= 768) {
        return '14px';
      } else if (width >= 769 && width <= 1024) {
        return '16px';
      } else {
        return '16px';
      }
    },
  };

  return (
    <div
      style={{
        position: 'relative',
        height: '100vh',
        width: '100%',
      }}
    >
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {Array(numPages)
          .fill(null)
          .map((_, index) => (
            <div
              style={{
                marginBottom: '20px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Page
                key={index}
                pageNumber={index + 1}
                width={windowWidth > 880 ? windowWidth / 2 : windowWidth}
              >
                {index === numPages - 1 && (
                  <SignatureCanvas
                    ref={sigCanvasRef}
                    penColor="black"
                    canvasProps={{
                      style: {
                        position: 'absolute',
                        top: '79%',
                        left: '65%',
                        transform: 'translate(-50%, -50%)',
                        height: '20%',
                        width: '50%',
                        border: '2px solid black',
                        zIndex: 10,
                      },
                    }}
                  />
                )}

                {index !== 0 &&
                  index !== numPages - 1 &&
                  (signedPages[index] ? (
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '2%',
                        right: '4%',
                        zIndex: 10,
                        color: 'black',
                        fontFamily: '"Homemade Apple", cursive',
                        fontSize: fontSizeStyles.getFontSize(),
                      }}
                    >
                      {initiales}
                    </div>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={() => handleSign(index)}
                      sx={{
                        position: 'absolute',
                        bottom: '1.5%',
                        right: '2%',
                        zIndex: 10,
                        fontSize: fontSizeStyles.getFontSize(),
                      }}
                    >
                      {t('TO_INITIAL_BUTTON')}
                    </Button>
                  ))}

                {index === 1 && (
                  <>
                    <TextField
                      id="name"
                      placeholder={t('ENTER_YOUR_NAME_HERE')}
                      variant="standard"
                      value={name}
                      onChange={handleNameChange}
                      InputProps={{
                        sx: {
                          fontSize: fontSizeStyles.getFontSize(),
                          color: 'black',
                        },
                      }}
                      sx={{
                        position: 'absolute',
                        top:
                          windowWidth >= 320 && windowWidth <= 425
                            ? '37%'
                            : windowWidth >= 426 && windowWidth <= 768
                            ? '37%'
                            : windowWidth >= 769 && windowWidth <= 1024
                            ? '37%'
                            : '37.8%',
                        left: '26%',
                        zIndex: 10,
                      }}
                    />
                    <TextField
                      id="address"
                      placeholder={t('ENTER_YOUR_ADDRESS_HERE')}
                      variant="standard"
                      value={address}
                      onChange={handleAddressChange}
                      InputProps={{
                        style: {
                          fontSize: fontSizeStyles.getFontSize(),
                          color: 'black',
                        },
                      }}
                      sx={{
                        position: 'absolute',
                        top:
                          windowWidth >= 320 && windowWidth <= 425
                            ? '41.6%'
                            : windowWidth >= 426 && windowWidth <= 768
                            ? '41%'
                            : windowWidth >= 769 && windowWidth <= 1024
                            ? '41.5%'
                            : '41.5%',
                        left: '28%',
                        width: '50%',
                        zIndex: 10,
                      }}
                    />
                  </>
                )}

                {index === numPages - 1 && (
                  <>
                    <TextField
                      id="contractor"
                      placeholder={t('ENTER_YOUR_NAME_HERE')}
                      variant="standard"
                      value={contractor}
                      onChange={handleContractorChange}
                      InputProps={{
                        style: {
                          fontSize: fontSizeStyles.getFontSize(),
                          color: 'black',
                        },
                      }}
                      sx={{
                        position: 'absolute',
                        top: '63%',
                        left: '55%',
                        zIndex: 10,
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={handleSend}
                      disabled={isSending}
                      style={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        bottom: '20px',
                        zIndex: 10,
                        fontSize: fontSizeStyles.getFontSize(),
                      }}
                    >
                      {isSending ? (
                        <CircularProgress
                          size={20}
                          style={{ color: 'white' }}
                        />
                      ) : (
                        t('SEND_CONTRACT_BUTTON')
                      )}
                    </Button>
                  </>
                )}
              </Page>
            </div>
          ))}
      </Document>
    </div>
  );
};

export default PDFViewer;
