import { useState } from 'react';
import FreeTrialService from '../services/post/freeTrial/postFreeTrial';
import {
  FreeTrialRequest,
  CommissionType,
} from '../services/post/freeTrial/models';
import { useAuth } from '../../../../context/AuthContext/AuthContext';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const useFreeTrial = () => {
  const { tokens, user } = useAuth();
  const hasToken = !!tokens;
  const { t } = useTranslation('freeTrial');

  const [activeStep, setActiveStep] = useState(0);
  const [selectedCard, setSelectedCard] = useState<number | null>(null);
  const [selectedAccountType, setSelectedAccountType] = useState<string | null>(
    null,
  );
  const [formValues, setFormValues] = useState<Record<string, string>>({
    email: '',
    firstName: '',
    lastName: '',
    country: '',
    city: '',
    street: '',
    postalCode: '',
    phoneNumber: '',
  });
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [stepError, setStepError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFormChange = (field: string, value: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const validateForm = () => {
    if (hasToken) {
      // Si l'utilisateur est connecté, pas besoin de valider le formulaire
      return true;
    }

    const errors: Record<string, string> = {};
    let hasErrors = false;

    // Valider tous les champs du formulaire
    Object.keys(formValues).forEach((field) => {
      if (!formValues[field].trim()) {
        errors[field] = t(
          'STEPPER_CONTENT.STEP_ZERO.ERRORS.THIS_FIELD_IS_REQUIRED',
        );
        hasErrors = true;
      }
    });

    setFormErrors(errors);
    return !hasErrors;
  };

  const handleNext = () => {
    if (activeStep === 0 && !validateForm()) {
      setStepError(
        t('STEPPER_TITLES.ERRORS.PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS'),
      );
      return;
    }

    // Ajuster les indices des étapes en fonction de l'état de connexion
    const accountStep = hasToken ? 0 : 1;
    const typeStep = hasToken ? 1 : 2;

    if (activeStep === accountStep && selectedCard === null) {
      setStepError(
        t('STEPPER_TITLES.ERRORS.PLEASE_SELECT_AN_ACCOUNT_TO_PROCEED'),
      );
      return;
    }

    if (activeStep === typeStep && selectedAccountType === null) {
      setStepError(
        t('STEPPER_TITLES.ERRORS.PLEASE_SELECT_AN_ACCOUNT_TO_PROCEED'),
      );
      return;
    }

    setStepError('');
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
      setStepError('');
    }
  };

  const startFreeTrial = async () => {
    setIsSubmitting(true);

    const balanceValues = [10000, 25000, 50000, 100000, 200000, 300000];
    const balance = balanceValues[selectedCard!];
    const type = selectedAccountType as CommissionType;

    let freeTrialData: FreeTrialRequest;

    if (hasToken) {
      // Si l'utilisateur est connecté, récupérer l'email depuis user.sub ou user.email
      freeTrialData = {
        email: user?.sub || '',
        role: '', // Définir role à null
        ipAddress: '', // Définir ipAddress à null
        balance,
        type,
      };
    } else {
      // Si l'utilisateur n'est pas connecté, récupérer les données depuis formValues
      freeTrialData = {
        email: formValues.email,
        phoneNumber: formValues.phoneNumber,
        firstname: formValues.firstName,
        lastname: formValues.lastName,
        street: formValues.street,
        city: formValues.city,
        zipCode: formValues.postalCode,
        country: formValues.country,
        role: '', // Définir role à null
        ipAddress: '', // Définir ipAddress à null
        referredBy: localStorage.getItem('affiliate') || Cookies.get('affiliate'),
        balance,
        type,
      };
    }

    try {
      const message = await FreeTrialService.startFreeTrial(freeTrialData);
      console.log(message);
      setActiveStep((prevStep) => prevStep + 1);
    } catch (error) {
      console.error('Failed to start free trial:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    activeStep,
    setActiveStep,
    selectedCard,
    setSelectedCard,
    selectedAccountType,
    setSelectedAccountType,
    formValues,
    handleFormChange,
    formErrors,
    stepError,
    handleNext,
    handleBack,
    startFreeTrial,
    isSubmitting,
    hasToken,
  };
};

export default useFreeTrial;
