import axios from 'axios';
import { logout } from '../services/auth/login';

const axiosInstance = axios.create({
  baseURL: `https://${process.env.REACT_APP_API_URL}/api/`,
});

const refreshTokenInstance = axios.create({
  baseURL: `https://${process.env.REACT_APP_API_URL}/api/`,
});

// Intercepteur pour ajouter le token d'accès aux requêtes
axiosInstance.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem('accessToken') ||
      sessionStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Intercepteur de réponse pour gérer les erreurs 403 et rafraîchir le token
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const refreshToken =
        localStorage.getItem('refreshToken') ||
        sessionStorage.getItem('refreshToken');

      if (!refreshToken) {
        logout(); // Déconnexion si le refreshToken est absent
        return Promise.reject(error);
      }

      try {
        const response = await refreshTokenInstance.post(
          'users/token/refresh-token',
          {
            refreshToken: refreshToken,
          },
        );

        if (response.status === 200) {
          const newAccessToken = response.data.accessToken;
          const newRefreshToken = response.data.refreshToken;

          // Mise à jour des tokens dans localStorage ou sessionStorage
          if (localStorage.getItem('refreshToken')) {
            localStorage.setItem('accessToken', newAccessToken);
            localStorage.setItem('refreshToken', newRefreshToken);
          } else {
            sessionStorage.setItem('accessToken', newAccessToken);
            sessionStorage.setItem('refreshToken', newRefreshToken);
          }

          // Répéter la requête originale avec le nouveau token
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (refreshTokenError) {
        logout();
        return Promise.reject(refreshTokenError);
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
