import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentWidget from './PaymentWidget';
import Cookies from 'js-cookie';

import { Link, useNavigate } from 'react-router-dom';
import { useThemeContext } from '../../context/ThemeContext/ThemeContext';
import { useAuth } from '../../context/AuthContext/AuthContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import {
  fetchShop,
  fetchShopConnected,
  applyPromoCode,
} from '../../services/shop/shop';
import QuantitySelector from '../../components/QuantitySelector/QuantitySelector';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';

import classes from './_buyChallenge.module.scss';
import { MuiTelInput } from 'mui-tel-input';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Modal,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import PixelBuyChallenge from '../../utils/pixel/PixelBuyChallenge';
import PixelPageView from '../../utils/pixel/PixelPageView';

import PercentIcon from '@mui/icons-material/Percent';
import HelpIcon from '@mui/icons-material/Help';

interface DiscountInfo {
  discountValue: number;
  discountPercentage: number;
  finalPrice: number;
}

interface QuantityItem {
  quantity: number;
  price: number;
  originalPrice: number;
}

interface Quantities {
  [key: string]: QuantityItem;
}

interface Challenge {
  challengeBalance: number;
  initialPrice: number;
  currentPrice: number;
}

const BuyChallenge = () => {
  useDocumentTitle('New Challenge');

  const { t } = useTranslation(['buyChallenge', 'common', 'challengesCards']);
  const { isDarkMode } = useThemeContext();
  const { tokens, isTokensLoading } = useAuth();
  const { getCode } = require('country-list');

  const prevTokenRef = useRef<string | null>(null);
  const location = window.location;
  const referredBy = new URLSearchParams(location.search).get('affiliate');
  const navigate = useNavigate();
  const [showPaymentWidget, setShowPaymentWidget] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [multiplierFactors, setMultiplierFactors] = useState<any>();
  const [user, setUser] = useState<any>();
  const [discountInfo, setDiscountInfo] = useState<DiscountInfo | null>(null);
  const [quantities, setQuantities] = useState<Quantities>({});
  const [useVirtualWallet, setUseVirtualWallet] = useState(false);
  const [countryOptions, setCountryOptions] = useState<string[]>([]);
  const [phone, setPhoneValue] = useState('');
  const [phoneIsValid, setPhoneIsValid] = useState<boolean>(false);
  const [walletDeduction, setWalletDeduction] = useState(0);
  const [selectedOption, setSelectedOption] = useState<string | undefined>('');
  const [selectedPayment, setSelectedPayment] = useState<string | undefined>(
    t('billingInfoCard.creditCard'),
  );
  const [phoneError, setPhoneError] = useState<string>('');

  const [showPopup, setShowPopup] = useState(false);
  const [pendingQuantityChange, setPendingQuantityChange] = useState<
    number | null
  >(null);

  if (referredBy !== null) {
    Cookies.set('affiliate', referredBy, { expires: 30 });
    localStorage.setItem('affiliate', referredBy);
  }

  function isValidPhoneNumber(phoneNumber: string): boolean {
    const cleanedPhoneNumber = phoneNumber.replace(/[\s-.]/g, '');
    const regex = /^\+[1-9]\d{1,14}$/;

    if (cleanedPhoneNumber.length < 9) {
      return false;
    }

    return regex.test(cleanedPhoneNumber);
  }

  useEffect(() => {
    if (user?.phoneNumber) {
      setPhoneValue(user.phoneNumber);
      setPhoneIsValid(true);
    }
  }, [user?.phoneNumber]);

  const handlePhoneChange = (newValue: string) => {
    if (isValidPhoneNumber(newValue)) {
      setPhoneError('');
      setPhoneIsValid(true);
    } else {
      setPhoneError(t('billingInfoCard.PLEASE_ENTER_A_VALID_PHONE_NUMBER'));
      setPhoneIsValid(false);
    }

    setPhoneValue(newValue);
  };

  const preparePaymentData = (event: any) => {
    const items = Object.keys(quantities).map((key) => {
      return {
        balance: key.replace('USD', ''),
        quantity: quantities[key].quantity,
        price: quantities[key].price,
      };
    });

    const formData = new FormData(event.target);
    const firstName = formData.get('firstname');
    const lastName = formData.get('lastname');
    const email = formData.get('email');
    const country = selectedOption;
    const city = formData.get('city');
    const street = formData.get('street');
    const zipCode = formData.get('postalCode');
    return {
      items,
      email: email,
      promoCode: promoCode,
      phoneNumber: user?.phoneNumber || phone,
      firstname: firstName,
      lastname: lastName,
      country: country || user?.country || 'Albania',
      zipCode: zipCode,
      city: city,
      street: street,
      ipAddress: user?.ipAddress,
      referredBy: localStorage.getItem('affiliate') || Cookies.get('affiliate'),
      role: 'ROLE_USER',
      useVirtualWallet: useVirtualWallet,
    };
  };

  const updateStates = (data: any) => {
    setChallenges(data.challenges);
    setMultiplierFactors(data);
    setUser(data);
    const initialQuantities = data.challenges.reduce(
      (acc: any, challenge: any) => {
        acc[`${challenge.challengeBalance}USD`] = {
          quantity: 0,
          price: challenge.currentPrice,
          originalPrice:
            challenge.currentPrice < challenge.initialPrice
              ? challenge.currentPrice
              : challenge.initialPrice,
        };
        return acc;
      },
      {},
    );
    setQuantities(initialQuantities);
  };

  const handlePayment = async (event: any) => {
    event.preventDefault();

    const hasSelectedItems = Object.values(quantities).some(
      (item) => item.quantity > 0,
    );
    if (!hasSelectedItems) {
      toast.info(t('billingInfoCard.PLEASE_SELECT_AT_LEAST_ONE_ARTICLE'), {
        progressStyle: { backgroundColor: 'cornflowerblue' },
      });
      return;
    }

    if (!phoneIsValid) {
      toast.error(t('billingInfoCard.INVALID_PHONE_NUMBER'), {
        progressStyle: { backgroundColor: 'red' },
      });
      return;
    }

    try {
      const paymentData = preparePaymentData(event);

      const url =
        selectedPayment === t('billingInfoCard.cryptoWallet')
          ? 'https://live.raisemyfunds.co/api/shop/initPayment'
          : 'https://live.raisemyfunds.co/api/shop/corefy/initiate';

      const response = await axios.post(url, paymentData);

      if (selectedPayment === t('billingInfoCard.cryptoWallet')) {
        if (response.data.paymentPage) {
          window.location.href = response.data.paymentPage;
        } else if (response.data.message === 'Payment completed using wallet') {
          navigate(`/buy-challenge/payment-status/${response.data.callbackId}`);
        }
      } else {
        const { finalAmount, externalId } = response.data;
        const {
          email,
          firstname,
          lastname,
          phoneNumber,
          street,
          city,
          zipCode,
          country,
        } = paymentData;
        localStorage.setItem(
          'paymentInfo',
          JSON.stringify({
            finalAmount,
            externalId,
            email,
            firstname,
            lastname,
            phoneNumber,
          }),
        );

        setShowPaymentWidget(true);
      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        toast.error(
          t('billingInfoCard.CANNOT_EXCEED_TOTAL_FUNDING_500K_FROM_CHALLENGES'),
          {
            progressStyle: { backgroundColor: 'red' },
          },
        );
      } else if (error.response?.status === 409) {
        toast.error(t('billingInfoCard.THIS_PHONE_NUMBER_IS_ALREADY_USE'), {
          progressStyle: { backgroundColor: 'red' },
        });
      } else {
        console.error("Erreur lors de l'initiation du paiement: ", error);
      }
    }
  };

  const [promoCode, setPromoCode] = useState('');
  const [promoError, setPromoError] = useState('');

  const handlePromoCodeChange = (e: any) => {
    setPromoCode(e.target.value);
  };

  const calculateSubtotal = useCallback(() => {
    return Object.entries(quantities).reduce(
      (sum, [key, { quantity, price }]) => {
        const numQuantity = Number(quantity);
        const numPrice = Number(price);
        return (
          sum +
          (isNaN(numQuantity) || isNaN(numPrice) ? 0 : numQuantity * numPrice)
        );
      },
      0,
    );
  }, [quantities]);

  useEffect(() => {
    const calculateWalletDeduction = () => {
      const subtotal = calculateSubtotal();
      let finalPrice = discountInfo ? discountInfo.finalPrice : subtotal;

      return useVirtualWallet
        ? Math.min(finalPrice, user?.currentWallet || 0)
        : 0;
    };

    setWalletDeduction(calculateWalletDeduction());
  }, [useVirtualWallet, user?.currentWallet, calculateSubtotal, discountInfo]);

  const calculateTotalCost = () => {
    const totalCost = Object.entries(quantities).reduce(
      (sum, [key, { quantity }]) => {
        const challenge = challenges.find(
          (ch) => `${ch.challengeBalance}USD` === key,
        );
        if (!challenge) return sum;

        const isFirstChallenge =
          key === `${challenges[0]?.challengeBalance}USD`;

        if (isFirstChallenge) {
          const firstUnitPrice = quantity >= 1 ? challenges[0].currentPrice : 0;
          const additionalUnitsPrice =
            quantity > 1 ? (quantity - 1) * challenges[0].initialPrice : 0;
          return sum + firstUnitPrice + additionalUnitsPrice;
        } else {
          const totalPrice = quantity * challenge.initialPrice;
          return sum + totalPrice;
        }
      },
      0,
    );

    const discountedPrice = discountInfo ? discountInfo.finalPrice : totalCost;

    return useVirtualWallet
      ? discountedPrice - walletDeduction
      : discountedPrice;
  };

  const handleApplyPromoCode = async () => {
    if (!promoCode) {
      setPromoError(t('billingInfoCard.enterPromoCodeError'));
      return;
    }

    // Vérifier si un 10K à 85€ est sélectionné
    const has10KAt85 = challenges.some(
      (ch) =>
        ch.challengeBalance === 10000 &&
        ch.currentPrice === 85 &&
        quantities['10000USD']?.quantity >= 1,
    );

    const useOffer = has10KAt85; // true si le 10K à 85€ est dans le panier

    try {
      // On récupère le prix affiché dans la sidebar
      const currentSidebarPrice = calculateTotalCost();

      let response;
      if (!tokens || !tokens.accessToken) {
        response = await applyPromoCode(
          null,
          promoCode,
          currentSidebarPrice,
          referredBy,
          useOffer,
        );
      } else {
        response = await applyPromoCode(
          tokens.accessToken,
          promoCode,
          currentSidebarPrice,
          null,
          useOffer,
        );
      }
      setDiscountInfo(response);
      setPromoError('');
    } catch (error) {
      setPromoError(t('billingInfoCard.failedToApplyPromoCode'));
      setDiscountInfo(null);
    }
  };

  useEffect(() => {
    fetch('https://preprod.raisemyfunds.com/api/localisation/allowed-countries')
      .then((response: any) => response.json())
      .then((data) => {
        setCountryOptions(data);
      });
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let data;
      if (tokens) {
        prevTokenRef.current = tokens.accessToken;
        data = await fetchShopConnected(tokens.accessToken);
      } else {
        data = await fetchShop();
      }
      updateStates(data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isTokensLoading) {
      fetchData();
    }
  }, [tokens, isTokensLoading]);

  const handleQuantityChange = (productKey: string, newQuantity: number) => {
    let popupTriggered = false;

    setQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      const firstChallengeKey = `${challenges[0]?.challengeBalance}USD`;

      if (productKey === firstChallengeKey) {
        // Condition supplémentaire : Afficher la popup seulement si initialPrice = 85
        if (
          challenges[0].currentPrice === 85 &&
          prevQuantities[firstChallengeKey].quantity === 1 &&
          newQuantity === 2
        ) {
          setPendingQuantityChange(newQuantity);
          setShowPopup(true);
          popupTriggered = true;
          return prevQuantities; // On ne modifie pas les quantités
        }

        // Mise à jour normale s'il n'y a pas eu de popup
        updatedQuantities[productKey] = {
          ...updatedQuantities[productKey],
          quantity: newQuantity,
          price:
            newQuantity === 1
              ? challenges[0].currentPrice
              : challenges[0].initialPrice,
        };
      } else {
        const challenge = challenges.find(
          (ch) => `${ch.challengeBalance}USD` === productKey,
        );
        if (challenge) {
          updatedQuantities[productKey] = {
            ...updatedQuantities[productKey],
            quantity: newQuantity,
            price: challenge.initialPrice,
          };
        }
      }

      return updatedQuantities;
    });

    // Ne réinitialiser la remise que si la popup n'a pas été déclenchée
    if (!popupTriggered) {
      setDiscountInfo(null);
    }
  };

  const paymentOptions =
    user?.country === 'Algeria' || selectedOption === 'Algeria'
      ? [t('billingInfoCard.cryptoWallet')]
      : [t('billingInfoCard.creditCard'), t('billingInfoCard.cryptoWallet')];

  const handleSelectPayment = (value: string) => {
    setSelectedPayment(value);
  };

  const handleSelect = (value: string) => {
    setSelectedOption(value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseVirtualWallet(event.target.checked);
  };

  const handleConfirmAdditionalChallenge = () => {
    if (pendingQuantityChange !== null) {
      const firstChallengeKey = `${challenges[0]?.challengeBalance}USD`;
      setQuantities((prevQuantities) => {
        const updatedQuantities = { ...prevQuantities };
        updatedQuantities[firstChallengeKey] = {
          ...updatedQuantities[firstChallengeKey],
          quantity: pendingQuantityChange,
          price: challenges[0].initialPrice,
        };
        return updatedQuantities;
      });
      setShowPopup(false);
      setPendingQuantityChange(null);
      setDiscountInfo(null);
    }
  };

  const handleCancelAdditionalChallenge = () => {
    // Au lieu de ne rien faire, on fait la même chose que handleConfirmAdditionalChallenge
    handleConfirmAdditionalChallenge();
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {showPaymentWidget && (
        <PaymentWidget onClose={() => setShowPaymentWidget(false)} />
      )}
      <PixelBuyChallenge />
      <PixelPageView />
      {!tokens ? (
        <nav className={classes.nav}>
          <div className={classes.mobileNav}>
            <Link to="https://raisemyfunds.co/">
              <img src={require('../../assets/images/squareLogo.png')} alt="" />
            </Link>
            <div className={classes.mobileButtons}>
              <Button variant="outlined" onClick={() => navigate('/login')}>
                Login
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate('/buy-challenge')}
              >
                Get Funded
              </Button>
            </div>
          </div>
          <div className={classes.wrapper}>
            <Link to="https://raisemyfunds.co/">
              <img src={require('../../assets/images/fullLogo.png')} alt="" />
            </Link>
            <ul>
              <li>
                <Link to={'https://raisemyfunds.co/the-challenge/'}>
                  The Challenge
                </Link>
              </li>
              <li>
                <Link to={'https://raisemyfunds.co/faq/'}>FAQ</Link>
              </li>
              <li>
                <Link to={'https://raisemyfunds.co/about-us/'}>About Us</Link>
              </li>
              <li>
                <Link to={'https://raisemyfunds.co/partner/'}>
                  Be a Partner
                </Link>
              </li>
              <li>
                <Link to={'https://raisemyfunds.co/contact-us/'}>
                  Contact Us
                </Link>
              </li>
            </ul>
            <div>
              <Button variant="outlined" onClick={() => navigate('/login')}>
                Login
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate('/buy-challenge')}
              >
                Get Funded
              </Button>
            </div>
          </div>
        </nav>
      ) : null}

      <div className={classes.mainContainer}>
        <div className={classes.challengeContainer}>
          {challenges.map((challenge: any, index: number) => {
            const q =
              quantities[`${challenge.challengeBalance}USD`]?.quantity || 0;
            return (
              <Card
                elevation={0}
                key={index}
                className={`${classes.card} ${
                  index === challenges.length - 1 ? classes.lastCard : ''
                }`}
                sx={{ padding: '16px', position: 'relative' }}
              >
                {index === 0 && (
                  <>
                    <Stack
                      sx={{
                        position: 'absolute',
                        width: '60px',
                        height: '60px',
                        backgroundColor: '#3988FF',
                        clipPath: 'polygon(0 0, 100% 0, 0 100%)',
                        top: 0,
                        left: 0,
                        display:
                          challenge.currentPrice === 85 && q < 2
                            ? 'flex'
                            : 'none',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        padding: '9px',
                      }}
                    >
                      <PercentIcon fontSize="small" />
                    </Stack>
                    <Tooltip
                      title={
                        <Box sx={{ fontSize: '14px', width: '400px' }}>
                          {t('challengesCards.FIRST_10K_CHALLENGE')}
                        </Box>
                      }
                      placement="top"
                      enterTouchDelay={0}
                      leaveTouchDelay={30000}
                      componentsProps={{
                        tooltip: {
                          sx: { maxWidth: 'none' },
                        },
                      }}
                    >
                      <HelpIcon
                        fontSize="large"
                        sx={{
                          display:
                            challenge.currentPrice === 85 && q < 2
                              ? 'block'
                              : 'none',
                          position: 'absolute',
                          top: '15px',
                          right: '15px',
                          color: '#3988FF',
                          fontSize: '32px',
                        }}
                      />
                    </Tooltip>
                  </>
                )}
                {index === challenges.length - 1 && (
                  <div className={classes.newBadge}>New</div>
                )}
                {index === challenges.length - 1 && (
                  <>
                    {[...Array(10)].map((_, i) => (
                      <div
                        key={i}
                        className="snowflake"
                        style={{
                          animationDelay: `${Math.random() * 5}s`,
                          left: `${Math.random() * 100}%`,
                        }}
                      >
                        ❄️
                      </div>
                    ))}
                  </>
                )}
                <h2>{`${challenge?.challengeBalance.toLocaleString(
                  'fr-FR',
                )} USD`}</h2>
                <table
                  className={`${classes.table} ${
                    isDarkMode ? classes.dark : classes.light
                  }`}
                >
                  <thead className={classes.table__header}>
                    <tr>
                      <th></th>
                      <th>{t('challengesCards.step')} 1</th>
                      <th>{t('challengesCards.step')} 2</th>
                    </tr>
                  </thead>
                  <tbody className={classes.table__body}>
                    <tr>
                      <td>{t('challengesCards.profitTarget')}</td>
                      <td>
                        {`${(
                          challenge?.challengeBalance *
                          multiplierFactors?.profitTargetPhase1
                        ).toLocaleString('fr-FR')}`}
                      </td>
                      <td>
                        {`${(
                          challenge?.challengeBalance *
                          multiplierFactors?.profitTargetPhase2
                        ).toLocaleString('fr-FR')}`}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('challengesCards.maxDailyLoss')}</td>
                      <td>
                        {`${(
                          challenge?.challengeBalance *
                          multiplierFactors?.maxDailyLoss
                        ).toLocaleString('fr-FR')}`}
                      </td>
                      <td>
                        {`${(
                          challenge?.challengeBalance *
                          multiplierFactors?.maxDailyLoss
                        ).toLocaleString('fr-FR')}`}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('challengesCards.maxLifetimeLoss')}</td>
                      <td>
                        {`${(
                          challenge?.challengeBalance *
                          multiplierFactors?.maxLifetimeLoss
                        ).toLocaleString('fr-FR')}`}
                      </td>
                      <td>
                        {`${(
                          challenge?.challengeBalance *
                          multiplierFactors?.maxLifetimeLoss
                        ).toLocaleString('fr-FR')}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className={classes.footer}>
                  <QuantitySelector
                    initialQuantity={q}
                    onQuantityChange={(newQuantity) =>
                      handleQuantityChange(
                        `${challenge.challengeBalance}USD`,
                        newQuantity,
                      )
                    }
                  />
                  <div className={classes.pricesContainer}>
                    <div className={classes.prices}>
                      {index === 0 ? (
                        q === 0 || q === 1 ? (
                          <>
                            <div
                              className={`${classes.price} ${
                                isDarkMode ? classes.dark : classes.light
                              }`}
                            >
                              {challenge.currentPrice} €
                            </div>
                            {challenge.currentPrice <
                              challenge.initialPrice && (
                              <div
                                className={`${classes.strikePrice} ${
                                  isDarkMode ? classes.dark : classes.light
                                }`}
                                style={{ textDecoration: 'line-through' }}
                              >
                                {challenge.initialPrice} €
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            className={`${classes.price} ${
                              isDarkMode ? classes.dark : classes.light
                            }`}
                          >
                            {challenge.initialPrice} €
                          </div>
                        )
                      ) : (
                        <>
                          {challenge?.currentPrice < challenge?.initialPrice ? (
                            <div
                              className={`${classes.price} ${
                                isDarkMode ? classes.dark : classes.light
                              }`}
                            >
                              {challenge.currentPrice} €
                            </div>
                          ) : null}
                          <div
                            className={`${
                              challenge?.currentPrice < challenge?.initialPrice
                                ? classes.strikePrice
                                : classes.price
                            } ${isDarkMode ? classes.dark : classes.light}`}
                          >
                            {`${challenge.initialPrice} €`}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
        <Card
          elevation={0}
          className={classes.sidebar}
          sx={{ padding: '16px' }}
        >
          <Typography variant="h5" component={'h5'} mb={4} fontWeight={'bold'}>
            {t('billingInfoCard.billingInfo')}
          </Typography>
          <form onSubmit={handlePayment}>
            <Box display="flex" justifyContent="space-between" mb={3}>
              <TextField
                id="firstname"
                name="firstname"
                label={t('billingInfoCard.firstname')}
                defaultValue={user?.firstName}
                variant="outlined"
                fullWidth
                required
                sx={{ mr: 1 }}
              />
              <TextField
                id="lastname"
                name="lastname"
                label={t('billingInfoCard.lastname')}
                defaultValue={user?.lastName}
                variant="outlined"
                fullWidth
                required
                sx={{ ml: 1 }}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" mb={3}>
              <TextField
                id="country"
                name="country"
                select
                label={t('billingInfoCard.country')}
                value={selectedOption || user?.country || ''}
                onChange={(e) => setSelectedOption(e.target.value)}
                variant="outlined"
                fullWidth
                required
              >
                {countryOptions.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="city"
                name="city"
                label={t('billingInfoCard.city')}
                defaultValue={user?.city}
                variant="outlined"
                fullWidth
                required
                sx={{ ml: 1 }}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" mb={3}>
              <TextField
                id="street"
                name="street"
                label={t('billingInfoCard.street')}
                defaultValue={user?.street}
                variant="outlined"
                fullWidth
                required
                sx={{ mr: 1 }}
              />
              <TextField
                id="postalCode"
                name="postalCode"
                label={t('billingInfoCard.postalCode')}
                defaultValue={user?.postalCode}
                variant="outlined"
                fullWidth
                required
                sx={{ ml: 1 }}
              />
            </Box>
            <Box mb={3}>
              <TextField
                id="email"
                name="email"
                label={t('billingInfoCard.emailLabel')}
                defaultValue={user?.email}
                variant="outlined"
                fullWidth
                required
              />
            </Box>
            <Box mb={2}>
              <MuiTelInput
                id="phone"
                name="phone"
                label={t('billingInfoCard.PHONE_NUMBER_LABEL')}
                defaultCountry={user?.country ? getCode(user?.country) : null}
                value={phone}
                onChange={handlePhoneChange}
                error={!!phoneError}
                helperText={phoneError}
                fullWidth
              />
              <Divider sx={{ mt: 4, mb: 4 }} />
            </Box>

            <Box mb={3}>
              <TextField
                id="paymentMethod"
                name="paymentMethod"
                select
                label={t('billingInfoCard.paymentMethod')}
                value={selectedPayment}
                onChange={(e) => setSelectedPayment(e.target.value)}
                variant="outlined"
                fullWidth
              >
                {paymentOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            <Stack direction={'row'} spacing={2} mb={3}>
              <TextField
                id="couponCode"
                label={t('billingInfoCard.couponCode')}
                value={promoCode}
                onChange={handlePromoCodeChange}
                variant="outlined"
                fullWidth
                sx={{ flex: 2 }}
                error={!!promoError}
                helperText={promoError || ''}
              />

              <Button
                variant="contained"
                onClick={handleApplyPromoCode}
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                {t('apply', { ns: 'common' })}
              </Button>
            </Stack>

            <Stack justifyContent={'space-between'} direction={'row'}>
              <Typography
                variant="subtitle1"
                component={'p'}
                fontWeight={'bold'}
                gutterBottom
              >
                {t('billingInfoCard.platform')}:
              </Typography>
              <Typography
                variant="subtitle1"
                component={'p'}
                fontWeight={'bold'}
                gutterBottom
              >
                Metatrader 5
              </Typography>
            </Stack>
            <Stack justifyContent={'space-between'} direction={'row'}>
              <Typography
                variant="subtitle1"
                component={'p'}
                fontWeight={'bold'}
                gutterBottom
              >
                {t('billingInfoCard.price')}:
              </Typography>
              <Typography
                variant="subtitle1"
                component={'p'}
                fontWeight={'bold'}
                gutterBottom
              >
                {calculateTotalCost()} €
              </Typography>
            </Stack>

            {discountInfo && (
              <Typography
                variant="body1"
                component={'p'}
                color={'#1FCC92'}
                gutterBottom
              >
                -{discountInfo.discountValue}€ (
                {discountInfo.discountPercentage}% Off)
              </Typography>
            )}
            {useVirtualWallet && (
              <Typography
                variant="body1"
                component={'p'}
                color={'#1FCC92'}
                gutterBottom
              >
                -{walletDeduction}€ (from virtual wallet)
              </Typography>
            )}

            {tokens && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useVirtualWallet}
                    onChange={(e) => setUseVirtualWallet(e.target.checked)}
                  />
                }
                label={t('useWalletCard.useWalletButton')}
              />
            )}

            <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
              {t('billingInfoCard.paymentButton')}
            </Button>
          </form>
        </Card>
      </div>

      <Card
        elevation={0}
        sx={{ padding: '16px', overflow: 'scroll' }}
        className={classes.conditions}
      >
        <h2>{t('challengeConditionsTable.challengeConditions')}</h2>

        <table
          className={`${classes.conditionTable} ${
            isDarkMode ? classes.dark : classes.light
          }`}
        >
          <thead className={classes.conditionTable__header}>
            <tr>
              <th></th>
              <th>{t('challengesCards.step')} 1</th>
              <th>{t('challengesCards.step')} 2</th>
              <th>{t('challengeConditionsTable.liveAccount')}</th>
            </tr>
          </thead>
          <tbody className={classes.conditionTable__body}>
            <tr>
              <td>{t('challengeConditionsTable.challengeDuration')}</td>
              <td>{t('challengeConditionsTable.unlimited')}</td>
              <td>{t('challengeConditionsTable.unlimited')}</td>
              <td>{t('challengeConditionsTable.unlimited')}</td>
            </tr>
            <tr>
              <td>{t('challengeConditionsTable.profitTarget')}</td>
              <td>10%</td>
              <td>5% </td>
              <td>-</td>
            </tr>
            <tr>
              <td>{t('challengeConditionsTable.maxDailyLoss')}</td>
              <td>5% </td>
              <td>5% </td>
              <td>5%</td>
            </tr>
            <tr>
              <td>{t('challengeConditionsTable.maxLoss')}</td>
              <td>10% </td>
              <td>10% </td>
              <td>10%</td>
            </tr>
            <tr>
              <td>{t('challengeConditionsTable.refundableFee')}</td>
              <td>{t('challengeConditionsTable.challengePrice')}</td>
              <td>{t('challengeConditionsTable.free')}</td>
              <td>{t('challengeConditionsTable.refund')}</td>
            </tr>
          </tbody>
        </table>
        <Typography fontSize={18} mt={2}>
          {t('challengeConditionsTable.ON_THE_FIRST_SCALE_UP')}
        </Typography>
      </Card>
      {!tokens ? (
        <footer className={classes.mainFooter}>
          <div>
            <p>{t('footer.paragraphOne')}</p>
            <br />
            <p>{t('footer.paragraphTwo')}</p>
            <br />
            <p>{t('footer.paragraphThree')}</p>
            <br />
            <p>{t('footer.paragraphFour')}</p>
          </div>
          <hr />
          <p className={classes.copyright}>{t('footer.copyrightText')}</p>
        </footer>
      ) : null}

      <Modal open={showPopup} onClose={handleCancelAdditionalChallenge}>
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
            maxWidth: '400px',
            width: '90%',
          }}
        >
          <Typography variant="h6" component="h2" mb={2}>
            {t('challengesCards.FIRST_10K_MODAL_TITLE')}
          </Typography>
          <Typography variant="body1" component={'p'} mb={3}>
            {t('challengesCards.FIRST_10_MODAL_TEXT')}
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={handleConfirmAdditionalChallenge}
            >
              {t('challengesCards.FIRST_10K_MODAL_BUTTON')}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default BuyChallenge;
