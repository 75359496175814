export const handleError = (error: any) => {
  if (error.response) {
    // Lorsque le serveur répond avec un code d'erreur
    console.error('Server responded with an error:', {
      status: error.response.status,
      data: error.response.data,
    });
  } else if (error.request) {
    // Lorsque la requête a été envoyée mais aucune réponse n'a été reçue
    console.error('No response received from server:', {
      request: error.request,
    });
  } else {
    // Une erreur s'est produite lors de la configuration de la requête
    console.error('Error setting up the request:', {
      message: error.message,
    });
  }

  // Lancer une nouvelle erreur personnalisée si nécessaire
  throw new Error(
    error.response?.data?.message ||
      error.message ||
      'An unexpected error occurred.',
  );
};
